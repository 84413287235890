import { codeSplit, Controller, FetchPageOptions, Result } from 'bernie-core';
import { PageData } from 'bernie-http';

export class TravelVideoLandingController implements Controller {
  public pageId = 'page.travel-video.landing';
  public path = '/:localeName/travel-video';
  public routeName = 'travel-video-landing';
  public bundles = [];

  public component = codeSplit( /* istanbul ignore next */
    () => import(/* webpackChunkName: "travel-video-landing" */ '../views/travel-video-landing-page'),
  );
  public exact = true;

  // tslint:disable-next-line:prefer-function-over-method
  public fetch(): Promise<Result> {
    return Promise.resolve({});
  }

  // tslint:disable-next-line:prefer-function-over-method
  public fetchPageData(options: FetchPageOptions): Promise<PageData> {
    const pageData = {
      title: 'Travel Video Program - Expedia Group',
      seo: {
        canonical: `https://affiliates.expediagroup.com/${options.params.localeName}/travel-video`,
        description: '"Share videos of stays found on Expedia and you could earn $150 for each video we feature on our sites, apps and social media!"'
      },
      clickstreamPageName: 'Travel Video Landing Page',
      clickstreamPageNameDetailed: 'Travel Video Landing Page',
    };

    return Promise.resolve(pageData) as Promise<PageData>;
  }
}
